import React from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'

import profilePic from './assets/images/eddy-on-the-rocks.jpg'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import Section from './Section'

const Home = () => {
  return (
    <>
      <Container maxWidth={'xl'}>
        <Section alignContent={'flex-start'}>
          <Grid container item xs={12} md={4} justify={'center'}>
            <img
              src={profilePic}
              alt="Eddy De Leon"
              style={{ width: '70%', height: '100%' }}
            />
          </Grid>

          <Grid item container xs={12} md={8}>
            <Grid item xs={12}>
              {' '}
              <Typography variant={'h4'} align={'center'}>
                Looking for your next software engineer? Look no further!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'overline'}>Nice to meet you!</Typography>
              <Typography variant={'body1'}>
                My name is Eddy De Leon. I have found that when I meet new
                people, they are used to the more common spelling of my name,
                “Eddie”, hence, “Eddy with a ‘Y’”!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'overline'}>
                Experienced and Motivated For More
              </Typography>
              <Typography variant={'body1'}>
                I am a Software Engineer with years of experience in front-end
                JavaScript development mainly using Angular, but with exposure
                to React as well. I am currently expanding my horizons with some
                projects that involve working with back-end development
                (Node.js/Express, PostgreSQL) and some DevOps work through
                Google Cloud Platform to host my content. I am always excited to
                work on interesting projects that challenge me. Will yours be
                next?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'overline'}>
                'Really Cool Guy!' - My Friends
              </Typography>
              <Typography variant={'body1'}>
                In my personal life, I am a huge nerd for video games. I built
                the computer I use every day at home myself, doing research
                until I settled on what parts I wanted based on the performance
                I desired for my games. I mainly play competitive games like
                first-person shooters and MOBAs like League of Legends as I
                enjoy the strategy and personal skill involved, but sometimes
                enjoy a game with an immersive story.
              </Typography>
              <Typography variant={'body1'}>
                Because I work with computers and spend plenty of my free time
                on a computer, I've developed a hobby for mechanical keyboards.
                For those who are curious, my daily driver right now is a
                Tokyo60v2 with Drop Holy Panda switches and GMK Oblivion v2 with
                the RAMA Git Keycap.
              </Typography>
              <Typography variant={'body1'}>
                My other hobbies include going on nature walks/hikes, keeping up
                with the latest consumer tech, watching movies and anime, and
                listening to all kinds of music. In the last year, I've started
                watching Formula 1 racing (Go Red Bull!) and started getting
                into cars after years of thinking the Toyota Prius was the most
                anyone would ever need in a car.
              </Typography>
            </Grid>
          </Grid>
        </Section>
        <Section>
          <Grid item xs={12} md={4}>
            <Typography variant={'h3'} align={'center'}>
              My previous professional experience includes...
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant={'subtitle1'}>
              <ul>
                <li>
                  <Typography variant={'h6'}>
                    California Resources Corporation - Software Engineer (June
                    2018 - Present)
                  </Typography>
                </li>
                <ul>
                  <li>
                    Plan, execute, and maintain several
                    <b>
                      <i> Angular 5+ </i>
                    </b>
                    projects used by various sectors of the company critical to
                    day-to-day operations, including, but not limited to,
                    software used in maintaining safety protocols and increasing
                    productivity of field employees, with estimated savings of
                    <b>
                      <i> $60,000 a month </i>
                    </b>
                    (based on man-hours saved).
                  </li>
                  <li>
                    Ensure a smooth{' '}
                    <b>
                      <i> offline-first </i>
                    </b>
                    user experience for workers in remote areas by utilizing
                    <b>
                      <i> NgRx </i>
                    </b>
                    and{' '}
                    <b>
                      <i>Dexie.js </i>
                    </b>
                    to cache important data on the users' devices to allow app
                    usage even when not connected to the internet.
                  </li>
                  <li>
                    Leverage{' '}
                    <b>
                      <i>Ionic Capacitor </i>
                    </b>
                    to create iOS and Android versions of our web applications,
                    then manage and deploy applications to Apple App Store and
                    Google Play Store.
                  </li>
                  <li>
                    Meet with stakeholders as necessary to gather requirements
                    for further improvements, and develop wireframes using{' '}
                    <b>
                      <i> Adobe XD </i>
                    </b>{' '}
                    to establish commitment to user stories.
                  </li>
                  <li>
                    Collaborate with team members to meet sprint goals and
                    deadlines across multiple projects through regular code
                    reviews and pair programming.
                  </li>
                  <li>
                    Hold regular knowledge transfer sessions with junior team
                    members to help increase their familiarity with code base
                    and business logic on other projects.
                  </li>
                </ul>
              </ul>
            </Typography>
          </Grid>
        </Section>
        <Section>
          <Grid item xs={12} md={4}>
            <Typography variant={'h3'} align={'center'}>
              I have experience working with tools such as...
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant={'h6'}>
              <ul>
                <li>JavaScript/TypeScript</li>
                <ul>
                  <li>Angular</li>
                  <li>React</li>
                  <li>Ionic/Ionic Capacitor</li>
                  <li>Apache Cordova</li>
                  <li>NgRx/RxJS/Redux</li>
                </ul>
                <li>HTML/CSS</li>
                <ul>
                  <li>Bootstrap</li>
                  <li>Sass</li>
                  <li>Angular Material</li>
                  <li>Material-UI</li>
                </ul>

                <li>Git</li>
                <li>Google Firebase</li>
                <li>WebStorm/IntelliJ/any JetBrains IDE</li>
                <li>Atlassian Tools (Jira/Confluence/Trello)</li>
              </ul>
            </Typography>
          </Grid>
        </Section>
        <Section>
          <Grid item xs={12} md={4}>
            <Typography variant={'h3'} align={'center'}>
              On my spare time I have worked/am working on...
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant={'subtitle1'}>
              <ul>
                <li>
                  <Typography variant={'h6'}>QTMD - Sole Developer</Typography>
                </li>
                <ul>
                  <li>
                    A Quentin Tarantino Movie Database, built using Angular 8
                    and Ionic 4, that shows information about each film shot
                    with Quentin Tarantino as director, including short summary,
                    cast, ratings from Rotten Tomatoes/IMDB/Metacritic.
                  </li>
                  <li>
                    IN PROGRESS: Utilize{' '}
                    <a href="https://api.themoviedb.org/3">
                      The Movie Database API
                    </a>{' '}
                    to grab movie data once a day, store on a PostgreSQL
                    database, and write a custom API to grab data for QTMD.
                  </li>
                </ul>
                <li>
                  <Typography variant={'h6'}>
                    <a href="https://shouldiwashmycar.today">
                      ShouldIWashMyCar.Today
                    </a>{' '}
                    - Front-End Development Consultant
                  </Typography>
                </li>
                <ul>
                  <li>
                    "Shouldiwashmycar is a program that determines 'weather' you
                    should wash your car based on a 10 day forecast. The rule of
                    thumb is to wash your car every 1-2 weeks. This program will
                    tell you to wash your car depending on whether it rains on
                    any given day within the next 10 days." - ShouldIWashMyCar
                    About Section
                  </li>
                  <li>
                    Tasked with helping update location services in the program
                    to improve results as well as update the user interface/user
                    experience to make it smoother and more appealing to users.
                  </li>
                </ul>
              </ul>
            </Typography>
          </Grid>
        </Section>
        <Section direction={'column'}>
          <Grid item xs={12}>
            <Typography variant={'h3'} align={'center'}>
              Interested? Impressed? Intrigued? Get in touch with me!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              href={
                'mailto:inquire@eddywithay.dev?subject=Hello Eddy, I liked what I saw! Got a chance to chat?'
              }
              aria-label={'Send an Email button'}
            >
              <Grid item xs={12}>
                <Grid container item xs={12} justify={'center'}>
                  <MailOutlineIcon style={{ fontSize: 120 }} />
                </Grid>
                <Grid container item xs={12} justify={'center'}>
                  <Typography variant={'h6'} align={'center'}>
                    Shoot me an email
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'} align={'center'}>
              OR
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              href={'https://www.linkedin.com/in/eddy-de-leon'}
              aria-label={'LinkedIn Contact Button'}
            >
              <Grid item xs={12}>
                <Grid container item xs={12} justify={'center'}>
                  <LinkedInIcon style={{ fontSize: 120 }} />
                </Grid>
                <Grid container item xs={12} justify={'center'}>
                  <Typography variant={'h6'} align={'center'}>
                    Connect with me on LinkedIn
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Section>
      </Container>
    </>
  )
}

export default Home
