import React from 'react'
import './App.scss'
import { Grid } from '@material-ui/core'

const Section = ({ children, direction, alignContent }) => {
  return (
    <>
      <Grid
        className={'viewHeightContainer'}
        container
        justify={'center'}
        direction={direction || 'row'}
        alignItems={'center'}
        alignContent={'center'}
        spacing={3}
      >
        {children}
      </Grid>
    </>
  )
}
export default Section
