import './App.scss'
import Home from './Home'
import {
  AppBar,
  Button,
  createMuiTheme,
  Grid,
  Hidden,
  ThemeProvider,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core'
import React from 'react'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import logoDark from './assets/images/logo-dark.png'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

function App() {
  const theme = createMuiTheme({
    typography: {
      h4: {
        fontFamily: 'Genome',
        fontWeight: 700,
      },
      h5: {
        fontFamily: 'Genome',
        fontWeight: 700,
      },
      h3: {
        fontFamily: 'Genome',
      },
      h2: {
        fontFamily: 'Genome',
      },
      h6: {
        fontFamily: 'Genome',
      },
      overline: {
        fontFamily: 'Genome',
        fontWeight: 600,
      },
    },
  })

  const StyledButton = withStyles({
    root: {
      fontFamily: 'Genome',
      fontWeight: 700,
    },
  })(Button)
  const BottomBar = withStyles({
    root: {
      top: 'auto',
      bottom: 0,
      background: 'black',
      position: 'sticky',
    },
  })(AppBar)
  //TODO: change scroll to do per section and not per increments of 100vh
  const handleScrollTo = (section) => {
    section === 4
      ? scrollToBottom()
      : window.scrollTo({
          top: (window.innerHeight - 160) * section,
          behavior: 'smooth',
        })
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  const buttons = [
    {
      name: 'About Me',
      sectionNumber: 0,
    },
    {
      name: 'Experience',
      sectionNumber: 1,
    },
    {
      name: 'Tools & Frameworks',
      sectionNumber: 2,
    },
    {
      name: 'Other Projects',
      sectionNumber: 3,
    },
    {
      name: 'Contact Me',
      sectionNumber: 4,
    },
  ].map((button) => (
    <StyledButton
      color={'inherit'}
      onClick={() => handleScrollTo(button.sectionNumber)}
      key={button.name}
    >
      {button.name}
    </StyledButton>
  ))

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppBar
          position={'sticky'}
          style={{ backgroundColor: 'black', marginBottom: '10px' }}
        >
          <Toolbar>
            <Grid container justify={'space-between'} alignItems={'center'}>
              {/*Create drawer for mobile*/}
              <Grid item>
                <Button onClick={() => handleScrollTo(0)}>
                  <img src={logoDark} alt="Eddy with a Y" />
                </Button>
                <Hidden smDown>{buttons}</Hidden>
              </Grid>

              <Grid item>
                <Button
                  color={'inherit'}
                  href={'https://github.com/eddyD45'}
                  aria-label={'GitHub Link'}
                >
                  <GitHubIcon />
                </Button>
                <Button
                  color={'inherit'}
                  href={'https://www.linkedin.com/in/eddy-de-leon'}
                  aria-label={'LinkedIn Link'}
                >
                  <LinkedInIcon />
                </Button>
                <Button
                  color={'inherit'}
                  href={
                    'mailto:inquire@eddywithay.dev?subject=Hello Eddy, I liked what I saw! Got a chance to chat?'
                  }
                  aria-label={'Send an Email button'}
                >
                  <MailOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Home />
        <BottomBar>
          <Toolbar variant={'dense'}>
            <Typography variant={'overline'}>
              Logo and site custom made by Eddy De Leon
            </Typography>
          </Toolbar>
        </BottomBar>
      </ThemeProvider>
    </div>
  )
}

export default App
